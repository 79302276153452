import { graphql } from 'gatsby';
import { ArticleListPage } from '../../components/Blog';
import { Article, BlogCategory } from '../../objects/Blog';

const ArticleList = ({
  data,
  pageContext,
}: {
  data: { allContentfulArticle: { edges: [{ node: Article }] } };
  pageContext: {
    blogRootPath: string;
    category: BlogCategory;
    categoryId: string;
    limit: number;
    skip: number;
    numPages: number;
    currentPage: number;
    canonicalPath: string;
  };
}) => {
  const { blogRootPath, category, numPages, currentPage, canonicalPath } =
    pageContext;

  return ArticleListPage({
    blogRootPath,
    articlesData: data.allContentfulArticle.edges,
    category,
    numPages,
    currentPage,
    canonicalPath,
  });
};

export default ArticleList;

export const pageQuery = graphql`
  query categoryArticles($categoryId: String!, $limit: Int!, $skip: Int!) {
    allContentfulArticle(
      filter: { category: { id: { eq: $categoryId } } }
      limit: $limit
      skip: $skip
      sort: { fields: [publishDate, title], order: [DESC, ASC] }
    ) {
      edges {
        node {
          id
          title
          node_locale
          slug
          publishDate(formatString: "MMMM D, YYYY")
          description
          heroImage {
            title
            description
            image: gatsbyImageData(width: 1920)
            mimeType
            url
          }
          body {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          author {
            name
            photo {
              title
              description
              image: gatsbyImageData(width: 100)
            }
          }
          tags
          readingTimeMinutes
          category {
            id
            name
            slug
            nodeLocale: node_locale
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
        }
      }
    }
  }
`;
